<template>
    <div>
        <b-loading v-model="isLoadingSendMail" :is-full-page="true"></b-loading>
        <div class="content">
            <h2 class="has-text-primary-dark">Gestión de publicaciones</h2>
        </div>
        <div class="card">
            <div class="card-content">
                <form @submit.prevent="searchCarga()">
                    <b-field label="Buscar" label-position="on-border" grouped>
                        <b-input
                            v-model="codigo"
                            placeholder="Código de la publicación"
                            type="search"
                            expanded
                        ></b-input>
                        <b-button
                            native-type="submit"
                            class="button is-primary"
                            :loading="isLoading"
                            :disabled="codigo === ''"
                        >
                            Buscar
                        </b-button>
                    </b-field>
                </form>
                <br />
                <b-table
                    :data="cargasDocumentos"
                    :loading="isLoadingCargasDocumentos"
                    :paginated="true"
                >
                    <b-table-column
                        field="periodo"
                        label="Periodo"
                        v-slot="props"
                    >
                        {{ props.row.periodo | period }}
                    </b-table-column>
                    <b-table-column
                        field="tipo_documento"
                        label="Tipo Documento"
                        v-slot="props"
                    >
                        {{ props.row.tipo_documento.descripcion }}
                    </b-table-column>
                    <b-table-column
                        field="cantidad_documentos"
                        label="Cantidad Documentos"
                        v-slot="props"
                    >
                        <b-tooltip label="Ver Documentos" type="is-dark">
                            <a @click.prevent="">
                                <span class="has-text-black">{{ props.row.cantidad_documentos }}</span>
                                <b-icon
                                    icon="open-in-new"
                                    size="is-small"
                                />
                            </a>
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column
                        field="fecha_publicacion"
                        label="Fecha Publicación"
                        v-slot="props"
                    >
                        {{ props.row.created_at | date }}
                    </b-table-column>
                    <b-table-column
                        field="usuario"
                        label="Usuario"
                        v-slot="props"
                    >
                        {{ props.row.perfil | nombreCompletoTrabajador }}
                    </b-table-column>
                    <b-table-column
                        field="envio"
                        label="Envio email"
                        v-slot="props"
                    >
                        {{ props.row.enviar_email ? 'SI' : 'NO' }}
                    </b-table-column>
                    <b-table-column
                        label="Enviar código"
                        v-slot="props"
                    >
                        <b-tooltip v-if="!props.row.activo" label="Enviar código por email" type="is-dark">
                            <a @click.prevent="sendCode(props.row.id)">
                                <b-icon icon="send" />
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">No hay registros</div>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal v-model="isActive">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">Información de la carga</p>
                </header>
                <div class="card-content">
                    <ul>
                        <li><b>Periodo:</b> {{ carga.periodo | period }}</li>
                        <li><b>Tipo de documento:</b> {{ carga.tipo_documento.descripcion }}</li>
                        <li><b>Cantidad de documentos:</b> {{ carga.cantidad_documentos }}</li>
                        <li><b>Fecha de publicación:</b> {{ carga.created_at | date }}</li>
                        <li><b>Usuario:</b> {{ carga.perfil | nombreCompletoTrabajador }}</li>
                        <li><b>Envio email:</b> {{ carga.enviar_email ? 'SI' : 'NO' }}</li>
                    </ul>
                    <br />
                    <b-button
                        type="is-danger"
                        icon-left="delete"
                        @click="isActiveForm = true"
                        outlined
                    >
                        Deshacer publicación
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="isActiveForm" :width="450">
            <div class="card">
                <div class="card-content">
                    <form @submit.prevent="destroyCarga">
                        <b-field
                            label="Ingrese Contraseña"
                            label-position="on-border"
                        >
                            <b-input
                                v-model="form.password"
                                name="contraseña"
                                type="password"
                                password-reveal
                                required
                            />
                        </b-field>
                        <b-button
                            type="is-primary"
                            native-type="submit"
                            :loading="isLoadingForm"
                            expanded
                        >
                            Realizar acción
                        </b-button>
                    </form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import Message from '@/utils/Message';

export default {
    data() {
        return {
            codigo: '',
            isLoading: false,
            isLoadingForm: false,
            isLoadingSendMail: false,
            isActive: false,
            isActiveForm: false,
            form: {
                password: '',
            },
            carga: {
                tipo_documento: {},
                perfil: { trabajador: { usuario: {} } }
            }
        };
    },
    computed: {
        cargasDocumentos() {
            return this.$store.state.cargasDocumentos.data;
        },
        isLoadingCargasDocumentos() {
            return this.$store.state.cargasDocumentos.isLoading;
        }
    },
    mounted() {
        this.fetchCargasDocumentos();
    },
    methods: {
        async fetchCargasDocumentos() {
            await this.$store.dispatch('cargasDocumentos/get');
        },
        async searchCarga() {
            this.isLoading = true;
            try {
                const { data } = await this.$store.dispatch('cargasDocumentos/show', this.codigo);

                this.isActive = true;
                this.carga = data;
            } catch (err) {
                Message.showErrorMessage(this, err.message || 'Error al obtener publicación');
            } finally {
                this.isLoading = false;
            }
        },
        async sendCode(id) {
            try {
                this.isLoadingSendMail = true;
                const { message } = await this.$store.dispatch('cargasDocumentos/sendMail', id);
                Message.showSuccessMessage(this, message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoadingSendMail = false;
            }
        },
        async destroyCarga() {
            this.isLoadingForm = true;
            try {
                const res = await this.$store.dispatch('cargasDocumentos/destroy', { id: this.carga.id, data: this.form });
                Message.showSuccessMessage(this, res.message);

                this.codigo = '';
                this.isActive = false;
                this.fetchCargasDocumentos();
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isActiveForm = false;
                this.isLoadingForm = false;
            }
        }
    },
    filters: {
        period: function(date) {
            return moment(date).format('MM/YYYY');
        },
        date: function(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        nombreCompletoTrabajador: function({ trabajador }) {
            return `${trabajador.apellido_paterno} ${trabajador.apellido_materno} ${trabajador.nombre}`;
        }
    }
};
</script>

<style></style>
